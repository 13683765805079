<template>
  <a-drawer
      title="快递费明细"
      placement="right"
      :width="'80vw'"
      :closable="true"
      :visible="visible"
      @close="onClose"
  >
    <div style="height: 80vh; overflow-y: scroll">
      <a-table
          @change="changePage"
          :pagination="pagination"
          :loading="loading"
          :columns="columns"
          :data-source="list"
          :rowKey="(record, index) => index"
      >
<!--   支付相关     -->
        <div slot="itemPayPriceAboutSlot" slot-scope="row">
          <div :class="payStatusClassType[row.status]">￥{{ row.changeMoney }}</div>
          <div :class="payTypeListClassType[row.payType]" class="mt-10">{{ computedPayTypeByLogisticsPriceListMapTypeText(row.payType) }}</div>
          <div :class="payStatusClassType[row.status]" class="mt-10">{{ computedPayStatusTypeListMapTypeText(row.status) }}</div>
          <div v-if="row.txnSeqno" :class="payStatusClassType[row.status]" class="mt-10">支付单号：{{ row.txnSeqno }}</div>
        </div>
<!--    描述    -->
        <div slot="itemRemarkSlot" slot-scope="row">
          <div :class="payStatusClassType[row.status]">{{ row.remarks }}</div>
        </div>
<!--    订单    -->
        <div slot="itemOrderNoSlot" slot-scope="row">
          <div v-if="row.orderNo">
            <span
                class="cur-pot color-blue"
                v-for="(orderNo, index) in computedOrderNosSplitArrList(row.orderNo)"
                :key="orderNo"
                @click="handleGoToOrder(orderNo)"
            >{{ orderNo + (computedOrderNosSplitArrList(row.orderNo).length -1 > index ? '，' : '') }}</span>
          </div>
        </div>
<!--    操作   -->
        <div slot="itemActionSlot" slot-scope="row">
          <a-button
              v-if="[2, 5].includes(row.status) && !row.returnTime"
              class="mt-10"
              type="primary"
              @click="returnLogisticsPricePopupEl(row)"
          >退还</a-button>
        </div>
      </a-table>
<!--   退还   -->
      <ReturnLogisticsPricePopup
          ref="returnLogisticsPricePopupEl"
          @change="getList"
      />
    </div>
  </a-drawer>
</template>

<script>
import {dqLogisticsPriceLineTableColumns} from "@/views/cmsPage/dqPlatform/userLogisticsPriceManage/_data"
import {getUserLogisticsPriceLineList} from "@/views/cmsPage/dqPlatform/userLogisticsPriceManage/_apis"
import {
  payStatusClassType,
  payStatusTypeMapText, payTypeByLogisticsPriceListMapType,
  payTypeListClassType,
} from "@/views/cmsPage/dqPlatform/_data"
import ReturnLogisticsPricePopup
  from "@/views/cmsPage/dqPlatform/userLogisticsPriceManage/_components/LogisticsPriceLinePopup/_components/ReturnLogisticsPricePopup/index.vue"

export default {
  components: {ReturnLogisticsPricePopup},
  data() {
    return {
      payTypeListClassType,
      payStatusClassType,
      visible: false,
      list: [],
      loading: false,
      params: {
        pageNum: 1,
        pageSize: 10,
        logisticsAccountId: undefined,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      columns: dqLogisticsPriceLineTableColumns
    };
  },
  computed: {
    computedOrderNosSplitArrList() {
      return (orderNos) => {
        return orderNos.split(',')
      }
    },
    computedPayTypeByLogisticsPriceListMapTypeText() {
      return value => payTypeByLogisticsPriceListMapType(value)
    },
    computedPayStatusTypeListMapTypeText() {
      return value => payStatusTypeMapText(value)
    },
  },
  methods: {
    show(data){
      this.params.pageNum = 1
      this.pagination.current = 1
      this.$set(this.params, "logisticsAccountId", data.id)
      this.visible = true
      this.getList()

    },
    /** 退还 */
    returnLogisticsPricePopupEl(row) {
      this.$refs.returnLogisticsPricePopupEl.show(row)
    },
    /** 去往订单页面 */
    handleGoToOrder(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/ordermannage?orderNo=${orderNo}`;
      window.open(url)
    },
    async getList() {
      this.loading = true;
      const res = await getUserLogisticsPriceLineList(this.params)
      this.loading = false;
      if (res.status !== '200') return
      this.list = res.data.records;
      this.pagination.total = res.data.total;
    },
    // 切换分页
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    onClose() {
      this.visible = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.notes {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  input {
    width: 300px;
    margin-right: 20px;
  }
}
::v-deep {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    height: 60px !important;
  }
}
</style>