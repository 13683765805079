var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "快递费明细",
        placement: "right",
        width: "80vw",
        closable: true,
        visible: _vm.visible
      },
      on: { close: _vm.onClose }
    },
    [
      _c(
        "div",
        { staticStyle: { height: "80vh", "overflow-y": "scroll" } },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.list,
              rowKey: function(record, index) {
                return index
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "itemPayPriceAboutSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c("div", { class: _vm.payStatusClassType[row.status] }, [
                      _vm._v("￥" + _vm._s(row.changeMoney))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "mt-10",
                        class: _vm.payTypeListClassType[row.payType]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.computedPayTypeByLogisticsPriceListMapTypeText(
                              row.payType
                            )
                          )
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mt-10",
                        class: _vm.payStatusClassType[row.status]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.computedPayStatusTypeListMapTypeText(row.status)
                          )
                        )
                      ]
                    ),
                    row.txnSeqno
                      ? _c(
                          "div",
                          {
                            staticClass: "mt-10",
                            class: _vm.payStatusClassType[row.status]
                          },
                          [_vm._v("支付单号：" + _vm._s(row.txnSeqno))]
                        )
                      : _vm._e()
                  ])
                }
              },
              {
                key: "itemRemarkSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c("div", { class: _vm.payStatusClassType[row.status] }, [
                      _vm._v(_vm._s(row.remarks))
                    ])
                  ])
                }
              },
              {
                key: "itemOrderNoSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    row.orderNo
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.computedOrderNosSplitArrList(row.orderNo),
                            function(orderNo, index) {
                              return _c(
                                "span",
                                {
                                  key: orderNo,
                                  staticClass: "cur-pot color-blue",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleGoToOrder(orderNo)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      orderNo +
                                        (_vm.computedOrderNosSplitArrList(
                                          row.orderNo
                                        ).length -
                                          1 >
                                        index
                                          ? "，"
                                          : "")
                                    )
                                  )
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e()
                  ])
                }
              },
              {
                key: "itemActionSlot",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      [2, 5].includes(row.status) && !row.returnTime
                        ? _c(
                            "a-button",
                            {
                              staticClass: "mt-10",
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.returnLogisticsPricePopupEl(row)
                                }
                              }
                            },
                            [_vm._v("退还")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          }),
          _c("ReturnLogisticsPricePopup", {
            ref: "returnLogisticsPricePopupEl",
            on: { change: _vm.getList }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }