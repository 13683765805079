<template>
  <a-modal
      v-model="showPopup"
      title="运费单笔-退还"
      :width="800"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-item label="最大金额">
          <div class="color-red ml-10">￥{{ maxPayMoney }}</div>
        </a-form-item>
        <a-form-item label="是否退回快递费">
          <a-radio-group v-model="isReturnAll">
            <a-radio-button :value="0">
              退回部分快递费
            </a-radio-button>
            <a-radio-button :value="1">
              退回全部快递费
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="部分退款金额" v-if="isReturnAll === 0">
          <a-input
              allowClear
              type="number"
              v-model="params.partReturnMoney"
              class="w-200 ml-10"
              placeholder='输入需要退款的金额'
          ></a-input>
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {returnLogisticsAccountPrice} from "@/views/cmsPage/dqPlatform/userLogisticsPriceManage/_apis"

export default {
  props: ['rateType'],
  data() {
    return {
      isReturnAll: 1,
      showPopup: false,
      maxPayMoney: 0,
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        logisticsAccountDetailId: undefined,
        partReturnMoney: undefined,
      },
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.maxPayMoney = data.changeMoney
      this.params.logisticsAccountDetailId = data.id
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.maxPayMoney = undefined
      this.params = {
        logisticsAccountDetailId: undefined,
        partReturnMoney: undefined,
      }
      this.isReturnAll = 1
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (this.isReturnAll === 0 && !this.params.partReturnMoney) {
        return this.$message.warn('部分退回快递费，金额不能为空')
      }
      if (this.isReturnAll === 0 && (Number(this.params.partReturnMoney) > Number(this.maxPayMoney))) {
        return this.$message.warn('部分退回快递费金额不能大于最大金额')
      }
      if (this.isReturnAll) {
        this.params.partReturnMoney = undefined
      }
      this.$loading.show()
      const res = await returnLogisticsAccountPrice(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('change')
      this.handleCancel()
    }
  }
}
</script>