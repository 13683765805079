
/** 快递费账户费用列表 */
export const dqLogisticsPriceTableColumns = [
    { title: "用户信息", align: "center", scopedSlots: {customRender: 'itemUserNameSlot'}, width: 200 },
    { title: "账户金额", align: "center", scopedSlots: {customRender: 'itemLogisticsPriceSlot'}, width: 150 },
    { title: "待处理状态/申请时间", align: "center", scopedSlots: { customRender: 'itemWaitHandleStatusSlotSlot' }, width: 150 },
    { title: "创建时间", align: "center", dataIndex: 'createTime', width: 200 },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
]
/** 快递费账户明细列表 */
export const dqLogisticsPriceLineTableColumns = [
    { title: "ID", align: "center", dataIndex: 'id', width: 50 },
    { title: "支付金额/方式", align: "center", scopedSlots: {customRender: 'itemPayPriceAboutSlot'}, width: 250 },
    { title: "描述", align: "center", scopedSlots: {customRender: 'itemRemarkSlot'}, width: 200 },
    { title: "订单", align: "center", scopedSlots: {customRender: 'itemOrderNoSlot'}, width: 200 },
    { title: "支付时间", align: "center", dataIndex: 'createTime', width: 100 },
    { title: "退回时间", align: "center", dataIndex: 'returnTime', width: 100 },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
]


/** 邮费排序--更新时间--升序降序 */
export const logisticsPriceOrUpdateTimeSortTypeList = [
    { name: '升序', value: 1 },
    { name: '降序', value: -1 },
]
