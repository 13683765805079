var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemUserNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "color-green" }, [
                  _vm._v(_vm._s(row.userName) + " 【"),
                  _c("span", { staticClass: "color-blue" }, [
                    _vm._v(_vm._s(row.userId))
                  ]),
                  _vm._v("】")
                ])
              ])
            }
          },
          {
            key: "itemLogisticsPriceSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "font-weight-bold",
                    class: row.logisticsMoney >= 0 ? "color-green" : "color-red"
                  },
                  [_vm._v(_vm._s(row.logisticsMoney))]
                )
              ])
            }
          },
          {
            key: "itemWaitHandleStatusSlotSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  { class: row.waitHandleStatus ? "color-red" : "color-green" },
                  [_vm._v(_vm._s(row.waitHandleStatus ? "待退回处理" : ""))]
                ),
                _c("div", { staticClass: "color-gray" }, [
                  _vm._v(_vm._s(row.waitHandleTime))
                ])
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mt-10",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowLogisticsLinePopup(row)
                        }
                      }
                    },
                    [_vm._v("明细")]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("LogisticsPriceLinePopup", { ref: "logisticsPriceLinePopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }