import instance from "@/axios"

/**  获取邮费账户列表 */
export const getUserLogisticsPriceList = async (params) => {
    return instance({
        url: '/dq_admin/userLogisticsAccount/list',
        method: 'GET',
        params: params
    })
}

/**  获取邮费账户明细列表 */
export const getUserLogisticsPriceLineList = async (params) => {
    return instance({
        url: '/dq_admin/userLogisticsAccount/detailList',
        method: 'GET',
        params: params
    })
}

/** 快递费-退还 */
export const returnLogisticsAccountPrice = async (params) => {
    return instance({
        url: '/dq_admin/userLogisticsAccount/returnLogisticsDetail',
        method: 'GET',
        params: params
    })
}