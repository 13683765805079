<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  用户信息    -->
      <div slot="itemUserNameSlot" slot-scope="row">
        <div class="color-green">{{ row.userName }} 【<span class="color-blue">{{ row.userId }}</span>】</div>
      </div>
<!--  账户金额    -->
      <div slot="itemLogisticsPriceSlot" slot-scope="row">
        <div class="font-weight-bold" :class="row.logisticsMoney >= 0 ? 'color-green' : 'color-red'">{{ row.logisticsMoney }}</div>
      </div>
<!--  待处理    -->
      <div slot="itemWaitHandleStatusSlotSlot" slot-scope="row">
        <span :class="row.waitHandleStatus ? 'color-red' : 'color-green'"
        >{{ row.waitHandleStatus ? '待退回处理' : '' }}</span>
        <div class="color-gray">{{ row.waitHandleTime }}</div>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button
            class="mt-10"
            type="primary"
            @click="handleShowLogisticsLinePopup(row)"
        >明细</a-button>
      </div>
    </a-table>
<!--  明细弹窗  -->
    <LogisticsPriceLinePopup ref="logisticsPriceLinePopupEl"/>
  </div>
</template>
<script>
import {dqLogisticsPriceTableColumns} from "@/views/cmsPage/dqPlatform/userLogisticsPriceManage/_data"
import LogisticsPriceLinePopup
  from "@/views/cmsPage/dqPlatform/userLogisticsPriceManage/_components/LogisticsPriceLinePopup/index.vue"

export default {
  components: {LogisticsPriceLinePopup},
  props: ['pagination', 'list'],
  data() {
    return {
      tableList: dqLogisticsPriceTableColumns,
    }
  },
  methods: {
    /** 查看明细 */
    handleShowLogisticsLinePopup(row) {
      this.$refs.logisticsPriceLinePopupEl.show(row)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>